export default {
    public: {
        copy_success: "复制成功",
        copy_success_1: "网址已复制，粘贴到浏览器即可访问！",
        download_success: "保存成功",
        button: {
            text: "确定",
            jump: "跳过",
            text_ok: "确认",
            text_ok1: "好的",
            cancel: "取消",
        },
        networkError: {
            text1: "错误，请重试！",
            text2: "登录过期或出现错误，请重新登录！",
            text3: "请求的资源不存在！",
            mail: "请在Supe App内打开!",
            errorProcess: "您不在App环境内，请打开Supe App访问！",
            errorMethodsNotDefine: "方法不存在!",
        },
        refresh: {
            text1: "向下拖动刷新...",
            text2: "松开刷新...",
            text3: "加载中...",
            text4: "加载成功~",
            text5: "没有更多了~",
        },
    },
    captchaFunError: {
        text1: "验证加载失败，请尝试刷新页面或切换网络！",
        text2: "发送过于频繁，请稍后再试！",
        text3: "发送过于频繁，请勿恶意调用！",
    },
    home: {
        error: {
            message_1: "Failed to claim. Please try again later!",
            message_2: "The network is busy now. Please try again later!",
            message_3: "You can only claim once within 24 hours!",
        },
    },
};
