export default {
    public: {
        copy_success: "Copied successfully",
        copy_success_1: "URL copied, paste it in your browser to access!",
        download_success: "Saved successfully",
        button: {
            text: "Confirm",
            jump: "Skip",
            text_ok: "Confirm",
            text_ok1: "OK",
            cancel: "Cancel",
        },
        networkError: {
            text1: "Error, please try again!",
            text2: "Login expired or error occurred, please login again!",
            text3: "The requested resource does not exist!",
            mail: "Please open it in the Supe App!",
            errorProcess: "You are not in the App environment, please open the Supe App to visit!",
            errorMethodsNotDefine: "The method does not exist!",
        },
        refresh: {
            text1: "Drag down to referesh...",
            text2: "Release to referesh...",
            text3: "Loading...",
            text4: "Loading successfully~",
            text5: "noMore~",
        },
    },
    captchaFunError: {
        text1: "Verification failed to load, please try to refresh the page or switch networks! ",
        text2: "Sent too frequently, please try again later! ",
        text3: "Sent too frequently, please do not call maliciously! ",
    },
    home: {
        error: {
            message_1: "Failed to claim. Please try again later!",
            message_2: "The network is busy now. Please try again later!",
            message_3: "You can only claim once within 24 hours!",
        },
    },
};
