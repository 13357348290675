//统一管理api
import { base_bome } from "./base"; // 导入接口域名列表
import { post } from "./tool"; // 导入http中创建的axios实例

export const getFaucetInfo = (params) => post(base_bome + "/faucet/info", params);
export const getFaucetClaim = (params) => post(base_bome + "/faucet/claim", params);

export default {
    getFaucetInfo,
    getFaucetClaim,
};
